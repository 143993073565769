import React from 'react';
import loadScript from "../components/loadScript"
import {changeLocale,injectIntl,IntlContextConsumer} from 'gatsby-plugin-intl'
import CountryImage from '../components/GuestBook/countryImage'
import availableLanguages from "../../static/availableLanguages.json"

class LanguageSwitcher extends React.Component {

  loadBundleScript() {
    var self = this;
    loadScript(document.location.origin + '/theme/output/bundle.js')
    .then((script) => {
      self.loadDropDown();
    })
    .catch((err) => {
        console.error(err.message);
    });
  };

  isSupportedLanguage(lng){
    return (lng.code === 'en' || 
            lng.code === 'pl' || 
            lng.code === 'fr' || 
            lng.code === 'de' || 
            lng.code === 'it' || 
            lng.code === 'ru' || 
            lng.code === 'es' || 
            lng.code === 'nl');
  };

  componentDidMount(){
    if (!window.$ || window.bootstrap === undefined) {
        this.loadBundleScript();
    }
    else{
      this.loadDropDown();
    }
  };

  loadDropDown(){
    if(this.props.contactForm !== "true"){
      //window.$('.dropdown-toggle').dropdown('dispose');
      window.$('.dropdown-toggle').dropdown();
    }
  };

  render(){

    if(this.props.contactForm !== "true"){
      return (
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) => (
              <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle dropdown-menu-languages-button" type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown">
                <CountryImage countryName={currentLocale}></CountryImage>
              </button>
              <div className="dropdown-menu dropdown-menu-languages" aria-labelledby="dropdownMenuButton">
              {availableLanguages.map(lng => (
                  <div 
                    key={lng.code}
                    id={lng.code + "_main_lng"}
                    className="dropdown-item" 
                    onClick={() => {if(this.isSupportedLanguage(lng)) changeLocale(lng.code)}}>
                      {this.isSupportedLanguage(lng)
                        ? <CountryImage countryName={lng.code}></CountryImage>
                        : <a href={lng.url} target="_blank" rel="noopener noreferrer"><CountryImage countryName={lng.code}></CountryImage></a>
                      }
                  </div>
                  ))}
              </div>
            </div>
          )}
        </IntlContextConsumer>
      )
    }
    else{
      return (
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) => (
            <select style={{ display: 'inline'}} className="select-language-contact-form"
            defaultValue={currentLocale}>
                {availableLanguages.map(lng => (
                    <option
                      key={lng.code}
                      value={lng.code}
                    >
                      {lng.label}
                    </option>
                ))}
            </select>
          )}
        </IntlContextConsumer>
      )
    }
  }
};

export default injectIntl(LanguageSwitcher)
