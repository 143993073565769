import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import BackgroundImage from 'gatsby-background-image'



class CountryImage extends React.Component {
  
  render(){
    var identifier = this.props.countryName;
    return (
      <StaticQuery
        query={graphql`
          query {
            allFile(filter: {relativeDirectory: {eq: "flags"}}) {
              edges {
                node {
                  childImageSharp {
                    fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  id
                  name
                  relativePath
                }
              }
            }
          }
        `}
        render={data => (
          <span>
            {
              data.allFile.edges.filter((value, index) => value.node.name === identifier).map((value) => {
              return <BackgroundImage Tag="div"
                        critical={true}
                        className="clint-pic"key={value.node.id}
                        data-id={value.node.id}
                        fluid={value.node.childImageSharp.fluid}
                    >
                    </BackgroundImage>
              })
            }
          </span>
        )}
      />)}}
  
CountryImage.propTypes = {
    countryName: PropTypes.string.isRequired
  }
  
export default CountryImage
