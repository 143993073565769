/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { withPrefix, StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import Footer from "./footer/footer"
import "./layout.css"
import {Helmet} from "react-helmet";
import { useIntl } from "gatsby-plugin-intl";


//theme js
//are imported as static files because webpack compile and generate errors

const Layout = ({ children, isPrinting, isMain }) => {
  const intl = useIntl();

    return (
        <StaticQuery
            query={graphql`
              query SiteTitleQuery {
                site {
                  siteMetadata {
                    title
                  }
                }
              }
            `}
            render={data => {
             // var renderHeaders = children.props.id !== "print-page";
              var header = null;
              !isMain && (header = <Header intl={intl} isPrinting={isPrinting} siteTitle={data.site.siteMetadata.title}/>);
              var footer = null;
              if(!isPrinting){
                footer = <Footer/>;
              }
              return (
                <>
                  <Helmet>
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap" />
                    <link rel="stylesheet"  type="text/css" href={withPrefix('theme/css/bootstrap.min.css')} />
                    <link rel="stylesheet"  type="text/css" href={withPrefix('theme/css/styles_minified.min.css')} />
                  </Helmet>
                  {header}
                  <div>
                    <main>
                      {children}
                    </main>
                    {footer}
                  </div>
                </>
              )
            }}
        />
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isPrinting: PropTypes.bool
}

export default Layout
