import React from "react"
import loadScript from "../components/loadScript"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import LanguageSwitcher from './LanguageSwitcher';
import { Link } from "gatsby-plugin-intl";

class Header extends React.Component {
	constructor() {
		super();
		//fix for support query string parameters. We had issues with google engine
		//Example: http://localhost:8000/es?gclid=Cj0KCQjw
		// this generated issues when use the search box
		this.state = {
		  queryString: "React",
		};
	}
	
	loadBundleScript() {
		var self = this;
		loadScript(document.location.origin + '/theme/output/bundle.js')
		.then((script) => {
				self.loadMenu();
		})
		.catch((err) => {
				console.error(err.message);
		});
	};

	loadMenu(){
		window.$('.nav-switch').on('click', function(event) {
			console.log('click on nav-switch');
			
			window.$('.main-menu').slideToggle(400);
			event.preventDefault();
		});
	};

	componentDidMount(){   
			if (!window.$) {
					this.loadBundleScript();
			}
			else{
				this.loadMenu();
			}
			this.setState({queryString : window.location.search});
	};

	renderContent(data){
		const headerStyles = "header-section "
			.concat(!this.props.isIndex ? "pagesHeader" : "")
			.concat(this.props.isPrinting ? " printHeader" : "");
		const menuLogoStyles = "main-menu-logo ".concat(!this.props.isIndex ? "pagesLogo" : "");
		const headerTop = "header-top ".concat(this.props.isPrinting ? "header-top-print" : "");
		const palmeraStyles = "palmeraHeader ".concat(this.props.isPrinting ? "palmeraLittle" : "");
		return (
				<header className={headerStyles}>
					{ !this.props.isIndex && <Img className={palmeraStyles} fluid={data.palmera.childImageSharp.fluid}/>}
					<div className={headerTop}>
						{ !this.props.isPrinting && 
							<div className="text-lg-right header-top-right">
								<div 
									className="top-info margin-added"
									style={{
										verticalAlign: "top"
									}}
								> 
									<LanguageSwitcher />
								</div>
								<div className="top-info main-top-info margin-added">
									<a href="tel:+34610168751"><i className="fa fa-whatsapp"></i></a>
								</div>
								<div className="top-info main-top-info margin-added">
									<a target="_blank" rel="noopener noreferrer" href="mailto:info@visaverde.com"><i className="fa fa-envelope"></i></a>
								</div>
								<div className="top-info main-top-info margin-added">
									<a href="https://www.facebook.com/Visaverde-663724596992451/" target="_blank"  rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
								</div>
							</div>
						}
					</div>
					<div className="row without-margins">
						<div className="col-12">
							<div className="main-site-navbar">
								<Link to={`/${this.state.queryString}`} className={menuLogoStyles} > 
									{ this.props.isIndex 
										? <Img fluid={data.logo.childImageSharp.fluid}/>
										: <Img fluid={data.logoPages.childImageSharp.fluid}/>
									}
								</Link>
								{ !this.props.isPrinting &&
									<div className="nav-switch main-menu-nav-switch">
										<i className="fa fa-bars"></i>
									</div>
								}
								<ul className="main-menu menu-main-page">
									{ !this.props.isPrinting &&
										<React.Fragment>
											<li className="main-menu-li"><Link to={`/${this.state.queryString}`}>{this.props.intl.formatMessage({ id:'Menu-home' })}</Link></li>
											<li className="main-menu-li"><Link to={`/property-sales${this.state.queryString}`}>{this.props.intl.formatMessage({ id:'Menu-propertySales' })}</Link></li>
											<li className="main-menu-li"><Link to={`/services${this.state.queryString}`}>{this.props.intl.formatMessage({ id:'Menu-services' })}</Link></li>
											<li className="main-menu-li"><Link to={`/guestbook${this.state.queryString}`}>{this.props.intl.formatMessage({ id:'Menu-clientComments' })}</Link></li>
											<li className="main-menu-li"><Link to={`/selling-your-property${this.state.queryString}`}>{this.props.intl.formatMessage({ id:'Menu-sellYourProperty' })}</Link></li>
											<li className="main-menu-li"><Link to={`/about${this.state.queryString}`}>{this.props.intl.formatMessage({ id:'Menu-aboutUs' })}</Link></li>
											<li className="main-menu-li"><Link to={`/contact-us${this.state.queryString}`}>{this.props.intl.formatMessage({ id:'Menu-contactUs' })}</Link></li>
										</React.Fragment>
									}
								</ul>
							</div>
						</div>
					</div>
				</header>
		)
	};

	render(){
		return(
            <StaticQuery
                query={graphql`
                	query {
										logo: file(relativePath: { eq: "LogoRedondo.png" }) {
												childImageSharp {
														fluid(maxWidth: 500) {
														...GatsbyImageSharpFluid
														}
												}
										},
										logoPages: file(relativePath: { eq: "logo1994.png" }) {
												childImageSharp {
														fluid(maxWidth: 500) {
														...GatsbyImageSharpFluid
														}
												}
										},
										palmera: file(relativePath: { eq: "palmeraPeq.png" }) {
											childImageSharp {
													fluid(maxWidth: 600) {
													...GatsbyImageSharpFluid
													}
											}
									},
									}
                `}
                render={data => 
                    this.renderContent(data)
                }
            />
			)}
}

export default Header;