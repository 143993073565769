import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl, Link } from "gatsby-plugin-intl";

const year = new Date().getFullYear();

const Footer = () => {
    const intl = useIntl();

    return (
    <StaticQuery
        query={graphql`
        query {
            abamaImg: file(relativePath: { eq: "footer/abama.jpg" }) {
                childImageSharp {
                    fixed(width: 140) {
                    ...GatsbyImageSharpFixed
                    }
                }
            },
            expertosImg: file(relativePath: { eq: "footer/expertos.png" }) {
                childImageSharp {
                    fixed(width: 140) {
                    ...GatsbyImageSharpFixed
                    }
                }
            },
            tsreaImg: file(relativePath: { eq: "footer/tsrea.png" }) {
                childImageSharp {
                    fixed(height: 65) {
                    ...GatsbyImageSharpFixed
                    }
                }
            },
            casangularImg: file(relativePath: { eq: "footer/casangularr.jpg" }) {
                childImageSharp {
                    fixed(height: 65) {
                    ...GatsbyImageSharpFixed
                    }
                }
            },
            logo: file(relativePath: { eq: "logo_long.png" }) {
                childImageSharp {
                    fixed(width: 290) {
                    ...GatsbyImageSharpFixed
                    }
                }
            },
            gMaps: file(relativePath: { eq: "icons/google-maps.png" }) {
                childImageSharp {
                    fixed(width: 50) {
                    ...GatsbyImageSharpFixed
                    }
                }
            },
            facebook: file(relativePath: { eq: "footer/facebook.png" }) {
                childImageSharp {
                    fixed(width: 62) {
                    ...GatsbyImageSharpFixed
                    }
                }
            },
            instagram: file(relativePath: { eq: "footer/instagram.png" }) {
                childImageSharp {
                    fixed(width: 50) {
                    ...GatsbyImageSharpFixed
                    }
                }
            },
            whatsapp: file(relativePath: { eq: "footer/whatsapp.png" }) {
                childImageSharp {
                    fixed(width: 50) {
                    ...GatsbyImageSharpFixed
                    }
                }
            },
            email: file(relativePath: { eq: "footer/email.png" }) {
                childImageSharp {
                    fixed(width: 50) {
                    ...GatsbyImageSharpFixed
                    }
                }
            }
        }
        `}
        render={data => 
            <>
                <div className="footer-contact-us">
                    <Link className="footer-contact-link" to="/contact-us" >{intl.formatMessage({ id:'Menu-contactUs' })}</Link>
                </div>
                <div className="footer-section">
                    <div 
                        className="container"
                        style={{
                            maxWidth: '100%'
                        }}
                    >
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-widget">
                                <Img fixed={data.logo.childImageSharp.fixed} style={{ width: `300px` }}/> 
                                <div id="help-menu-footer">
                                    <div className="FooterLinkRow">
                                        <Link className="footer-link" to="/legal-advice" >{intl.formatMessage({ id:'LegalAdvice' })}</Link>
                                    </div>
                                    <div className="FooterLinkRow">
                                        <Link className="footer-link" to="/privacy-policy" >{intl.formatMessage({ id:'PrivacyPolicy' })}</Link>
                                    </div>
                                    <div className="FooterLinkRow">
                                        <Link className="footer-link" to="/cookies-policy" >{intl.formatMessage({ id:'CookiesPolicy' })}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 footer-widget">
                                <div className="footer-logos">
                                    <a 
                                        href="https://www.facebook.com/Visaverde-663724596992451/" 
                                        target="_blank"  rel="noopener noreferrer"
                                    >
                                        <Img 
                                            className="contactImgIcon" 
                                            fixed={data.facebook.childImageSharp.fixed}>
                                        </Img>
                                    </a>
                                    <a 
                                        href="https://www.instagram.com/visaverde1994/" 
                                        target="_blank"  rel="noopener noreferrer"
                                    >
                                        <Img 
                                            className="contactImgIcon" 
                                            fixed={data.instagram.childImageSharp.fixed}>
                                        </Img>
                                    </a>
                                    <a 
                                        href="https://g.page/visaverde?share" 
                                        target="_blank"  rel="noopener noreferrer"
                                    >
                                        <Img 
                                            className="contactImgIcon" 
                                            fixed={data.gMaps.childImageSharp.fixed}>
                                        </Img>
                                    </a>
                                    <a  href="tel:+34610168751" 
                                        target="_blank"  
                                        rel="noopener noreferrer"
                                    >
                                        <Img 
                                            className="contactImgIcon" 
                                            fixed={data.whatsapp.childImageSharp.fixed}>
                                        </Img>
                                    </a>
                                    <a  
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        href="mailto:info@visaverde.com"
                                    >
                                        <Img 
                                            className="contactImgIcon" 
                                            fixed={data.email.childImageSharp.fixed}>
                                        </Img>
                                    </a>
                                </div>
                                <div className="contact-widget">
                                    <p><i className="fa fa-phone"></i><a href="tel:+34922794214">+34-922794214</a></p>
                                    <p><i className="fa fa-map-marker"></i>Calle Alemania numero 11, CC Patio Canario, local numero 4, San Eugenio Alto, 38660 Adeje</p>
                                    <p><i className="fa fa-clock-o"></i>Office hours: Monday till Friday  9.30 - 18.00</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 colaboration-logos">
                                <div>
                                    <Img 
                                        fixed={data.tsreaImg.childImageSharp.fixed}
                                        style={{
                                            boxShadow:"0px 0px 10px #375237"
                                        }}
                                    /> 
                                    <Img 
                                        fixed={data.casangularImg.childImageSharp.fixed}
                                        style={{
                                            boxShadow:"0px 0px 10px #375237"
                                        }}
                                    /> 
                                </div>
                                <div>
                                    <Img 
                                        fixed={data.expertosImg.childImageSharp.fixed}
                                        style={{
                                            boxShadow:"0px 0px 10px #375237"
                                        }}
                                    /> 
                                </div>
                                <div>
                                    <Img 
                                        fixed={data.abamaImg.childImageSharp.fixed}
                                        style={{
                                            boxShadow:"0px 0px 10px #375237"
                                        }}
                                    /> 
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="copyright">
                                <p>{intl.formatMessage({ id:'Copyright' })} &copy;{year} {intl.formatMessage({ id:'AllRightsReserved' })}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    />
)}

export default Footer
